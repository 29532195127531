import {isNotBlank} from "./common-string.js";

/**
 * Convert a value to a boolean.
 *
 * @param {*} value - The value to convert to boolean.
 * @returns {boolean} - The converted boolean value.
 */
export function parseBoolean(value) {
    if (typeof value === "boolean") {
        return value;
    } else if (typeof value === "string") {
        value = value.trim().toLowerCase();
        return !(value === "false" || value === "null" || value === "nan" || value === "undefined" || value === "0" || value === "no" || value === "");
    } else if (typeof value === "number") {
        return value > 0;
    } else {
        return Boolean(value);
    }
}

window.parseBoolean = parseBoolean;

/**
 * Sanitize the phone number content.
 *
 * @param {string} phoneNumber the phone number
 * @returns string empty string for invalid or restricted number, or a string of the valid phone number digits
 * @see <a href="https://en.wikipedia.org/wiki/North_American_Numbering_Plan">"Modern" North American Numbering Plan</a>
 */
export function sanitizePhone(phoneNumber) {
    let number = "";
    if (isNotBlank(phoneNumber)) {
        const phoneNumberMatch = phoneNumber.trim().match(/^\+?1? ?\(?([2-9][0-8]\d)\)?[. -]{0,3}([2-9]\d{2})[. -]{0,3}(\d{4})$/);
        if (phoneNumberMatch) {
            const areaCode = phoneNumberMatch[1];
            const exchangeCode = phoneNumberMatch[2];
            const lineNumber = phoneNumberMatch[3];
            const restrictedAreaCodes = [
                "000", "200", "211", "222", "233", "255", "266", "277", "288", "300", "306", "311", "333", "333", "344", "354",
                "355", "365", "366", "367", "368", "382", "388", "400", "403", "411", "416", "418", "421", "422", "433", "437",
                "438", "444", "450", "455", "460", "466", "468", "474", "477", "487", "488", "500", "506", "511", "514", "519",
                "521", "522", "523", "524", "525", "526", "527", "528", "529", "532", "533", "535", "538", "542", "543", "544",
                "545", "546", "547", "548", "549", "550", "552", "553", "554", "555", "556", "558", "566", "568", "577", "578",
                "579", "581", "584", "587", "588", "589", "600", "604", "611", "613", "633", "639", "644", "647", "655", "666",
                "671", "672", "677", "683", "684", "688", "700", "705", "709", "710", "711", "722", "733", "744", "753", "777",
                "780", "782", "788", "800", "807", "811", "819", "822", "825", "833", "844", "851", "855", "866", "867", "873",
                "877", "879", "880", "881", "882", "883", "884", "885", "886", "887", "888", "889", "900", "902", "905", "911",
                "922", "933", "942", "944", "950", "955", "977", "988", "999",
            ];
            const restrictedExchangeCodes = [
                "211", "222", "311", "333", "411", "444", "511", "555", "611", "666", "711", "777", "811", "911", "950", "958",
                "959", "976", "999",
            ];
            if (!arrayIncludes(restrictedAreaCodes, areaCode) && !arrayIncludes(restrictedExchangeCodes, exchangeCode)) {
                number = areaCode + exchangeCode + lineNumber;
            }
        }
    }

    return number;
}

/**
 * Backwards compatible alternative to array.includes(needle).
 * @param array the array to search
 * @param needle the value to search for
 * @returns {boolean} ture if found
 */
export function arrayIncludes(array, needle) {
    for (const item of array) {
        if (item === needle) {
            return true;
        }
    }

    return false;
}
